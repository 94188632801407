<template>
  Настройки
</template>

<script>
export default {

}
</script>

<style lang = "scss">

</style>